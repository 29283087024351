import { useCallback, useEffect, useRef } from 'react';

export default function useInfiniteQuery( fetchNextPage, hasNextPage, isFetching ) {
	const observer = useRef<IntersectionObserver>();
	
	const lastElementRef = useCallback( ( node: HTMLElement | null ) => {
		if ( isFetching || !hasNextPage ) return;
		
		if ( observer.current ) observer.current.disconnect();
		
		observer.current = new IntersectionObserver( ( entries ) => {
			if ( entries[ 0 ].isIntersecting ) {
				fetchNextPage().finally();
			}
		} );
		
		if ( node ) observer.current.observe( node );
	}, [ fetchNextPage, hasNextPage, isFetching ] );
	
	useEffect( () => () => {
		if ( observer.current ) {
			observer.current.disconnect();
		}
	}, [] );
	
	return lastElementRef;
}
