import { mutateGraphQL } from '@/data/apollo';
import { gql } from '@apollo/client';
import { MutationStandingCommerceProcessArgs } from '../../../../types/schema';

export const OrderGQLWrite = gql`
	mutation OrderWrite_fc56($id: String, $externalId: String,$externalValue: String $method: String, $customNumber: Boolean, $input: OrderValidator, $remove: Boolean) {
		orderWrite(id: $id, externalId: $externalId,externalValue: $externalValue, method: $method,customNumber: $customNumber, input: $input, remove: $remove) {
			id
		}
	}
`;

export const generateRecurring = async ( orderId: string ) => {
	await mutateGraphQL<MutationStandingCommerceProcessArgs>( {
		mutation: gql`
			mutation standingCommerceProcess_6563($commerceId: String!, $syncOnly: Boolean) {
				standingCommerceProcess(commerceId:$commerceId, syncOnly: $syncOnly)
			},`,
		variables: { commerceId: orderId, syncOnly: true },
	} );
};
