import Actions from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import PageWrapper from '@/components/page/wrapper';
import RecurringInvoicesSkeleton from '@/components/skeletons/recurringInvoicesSkeleton';
import StyledImage from '@/components/styledImage';
import { useGraphQL, useInfiniteGraphQL } from '@/data';
import { CommercePublicRead } from '@/data/commerce/commerce.graphql';
import { StandingInvoices } from '@/data/commerce/invoice.graphql';
import { StandingOrders } from '@/data/commerce/order.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import useInfiniteQuery from '@/hooks/useInfiniteQuery';
import CommerceStatusChip from '@/pages/dashboard/commerce/chips/commerceStatusChip';
import { generateRecurring } from '@/pages/dashboard/commerce/orders/orderGQL';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModalControls } from '@/providers/modal';
import {
	Order,
	QueryCommercePublicReadArgs,
	QueryStandingInvoicesArgs,
	QueryStandingOrdersArgs,
} from '@/types/schema';
import { Add as AddIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from '@mui/lab';
import { Box, Fade, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { isEmpty, startCase, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { RecurringSettings } from './recurring';
import { findEarliestDate, findNextOccurrenceDate, getDueDateInfo } from './repeatDateModal/utils';

function SingleInvoice( { invoice } ) {
	const { getDueDateText, getDueDateColor } = getDueDateInfo( invoice );
	const { t } = useTranslation();
	return (
		<Paper sx={{ p: 1, width: { xs: '100%', sm: 300 }, bgcolor: 'background.default' }}>
			<Stack
				width='100%'
				direction='row'
				alignItems='start'
				justifyContent='space-between'>
				<Stack spacing={.5}>
					<Typography color='text.secondary' fontWeight={500}>
						#{invoice.metadata?.customNumber || invoice.number || '-'}
					</Typography>
					<Typography color='text.secondary'>
						{safeFormatInTimeZone( invoice.serviceDate || invoice.updatedAt, 'PPp' )}
					</Typography>
				</Stack>
				<Box
					textAlign='right'
					sx={{ alignSelf: 'start' }}>
					<Stack
						direction='row'
						spacing={1}
						alignItems='right'
						sx={{ alignSelf: 'end', alignItems: 'center', justifyContent: 'end' }}>
						{invoice?.externalId && (
							<Tooltip title={t( 'commerce:available-on-clover' )}>
								<StyledImage
									alt='clover-pos-image'
									src='/images/clover-icon.png'
									width='10px'
									height='10px'
								/>
							</Tooltip>
						)}
						{!isEmpty( invoice?.attachments )
							&& <AttachFileIcon sx={{ fontSize: 12 }}/>}
						{invoice.status && (
							<CommerceStatusChip cellData={invoice}/>
						)}
					</Stack>
					<Typography variant='h6' fontWeight='500' my={1}>
						{currencyFormat( Math.abs( +invoice.grandTotal ) )}
					</Typography>
					<Tooltip
						placement='right'
						title={safeFormatInTimeZone( invoice.dueDate, 'PP' )}>
						<Typography color={getDueDateColor()}>{getDueDateText()}</Typography>
					</Tooltip>
				</Box>
			</Stack>
		</Paper>
	);
}

export default function HistoryCommerces( { data }: { data: Order } ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const event = useEvents();
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	const invoicesEditable = usePermissions( permissions.invoices.write );
	const ordersEditable = usePermissions( permissions.orders.write );
	
	const {
		      flattenedData,
		      isLoading,
		      fetchNextPage,
		      hasNextPage,
		      isFetching,
		      count,
	      } = useInfiniteGraphQL<QueryStandingInvoicesArgs | QueryStandingOrdersArgs>( {
		queryKey : [ 'standingInvoices', 'standingOrders' ],
		query    : data.type === 'ORDER' ? StandingOrders : StandingInvoices,
		variables: {
			options: {
				limit: 10,
				// orderBy: [ 'createdAt:ASC' ],
			},
			orderId  : data.metadata?.originalCommerce || data.id,
			invoiceId: data.metadata?.originalCommerce || data.id,
		},
	}, {} );
	
	const { data: commerceParent, isLoading: parentIsLoading } = useGraphQL<QueryCommercePublicReadArgs>( {
		queryKey : [ 'CommercePublicRead' ],
		query    : CommercePublicRead,
		variables: {
			id: data.metadata?.originalCommerce || data.id,
		},
	}, { placeholderData: undefined } );
	
	const parentInvoice = commerceParent?.commercePublicRead;
	
	const startingDatePattern = findEarliestDate( parentInvoice?.standingData );
	const nextRecurringDate = findNextOccurrenceDate( parentInvoice?.standingData );
	const loweredType = toLower( parentInvoice?.type );
	
	const lastElementRef = useInfiniteQuery( fetchNextPage, hasNextPage, isFetching );
	
	if ( isLoading || parentIsLoading ) return <RecurringInvoicesSkeleton hasHeader/>;
	
	return (
		<PageWrapper
			modalWrapper
			hideBack
			boxProps={{
				sx: {
					'overflowX'        : 'hidden',
					'width'            : '700px',
					'@keyframes pulse2': {
						'0%': {
							transform: 'scale(1)',
						},
						'50%': {
							transform: 'scale(1.1)',
						},
						'100%': {
							transform: 'scale(1)',
						},
					},
				},
			}}
			primary={`${t( 'common:generated' )} ${startCase( loweredType )}s for #${parentInvoice.metadata?.customNumber || parentInvoice.number}`}
			actions={[ staff && {
				name   : 'Create New',
				icon   : <AddIcon/>,
				onClick: async () => {
					enqueueSnackbar( `Please wait while the new ${loweredType} is being generated...`, { variant: 'info' } );
					try {
						await generateRecurring( data.id );
						event.emit( 'reload.graphqlQuery', true );
						enqueueSnackbar( `${loweredType} is generated successfully.`, { variant: 'success' } );
					} catch {
						enqueueSnackbar( `Failed to generate ${loweredType}.`, { variant: 'error' } );
					}
					
					closeModal();
				},
			} ]}>
			<Fade in timeout={1000}>
				<Box>
					<Paper sx={{ bgcolor: 'background.default', p: 2 }}>
						<Stack direction='row' alignItems='center' justifyContent='space-between'>
							<Stack>
								<Typography>
									Recurring history
									for {startCase( loweredType )} #{parentInvoice.metadata?.customNumber || parentInvoice.number}
								</Typography>
								<Typography>
									<Typography component='span' color='text.secondary'>
										Client: {' '}
									</Typography>
									{parentInvoice?.client?.name || parentInvoice?.client?.contact || parentInvoice?.client?.email || '-'}
								</Typography>
								<Typography color='text.secondary'>
									{safeFormatInTimeZone( parentInvoice?.serviceDate || parentInvoice?.updatedAt, 'PPp' )}
								</Typography>
								<Typography>
									<Typography component='span' color='text.secondary'>
										{t( 'common:by' )}: {' '}
									</Typography>
									{parentInvoice?.staff?.user?.firstName || '-'}
								</Typography>
								{parentInvoice?.status && (
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography color='text.secondary'>
											Status:
										</Typography>
										<Box>
											<CommerceStatusChip cellData={parentInvoice}/>
										</Box>
									</Stack>
								)}
							</Stack>
						</Stack>
					</Paper>
					<Paper sx={{ bgcolor: 'background.default', p: 2, mt: 2 }}>
						<RecurringSettings order={parentInvoice}/>
						{Boolean( flattenedData.length ) && (
							<Typography color='text.secondary'>
								So far, {count} {loweredType}(s) have been generated
							</Typography>
						)}
						{nextRecurringDate && (
							<Typography color='text.secondary'>
								The next {loweredType} will be generated
								on {safeFormatInTimeZone( nextRecurringDate, 'PPP' )}
							</Typography>
						)}
					</Paper>
					{Boolean( flattenedData.length ) ? (
						<Timeline>
							<TransitionGroup component={null}>
								{flattenedData.map( ( invoice, index ) => (
									<Fade key={invoice.id} in timeout={1000}>
										<TimelineItem
											key={index}
											ref={index === flattenedData.length - 1 ? lastElementRef : undefined}
											sx={{
												'position': 'relative',
												':after'  : {
													position    : 'absolute',
													content     : '""',
													left        : 0,
													right       : 0,
													bottom      : 0,
													top         : 0,
													borderRadius: 2,
													bgcolor     : 'divider',
													zIndex      : -1,
													display     : data.number === invoice.number ? 'block' : 'none',
													animation   : 'pulse2 1s 2',
												},
											}}>
											<TimelineOppositeContent
												sx={{ flex: 0.1, maxWidth: 100, mt: .5 }}
												color='textSecondary'>
												{safeFormatInTimeZone( invoice.createdAt, 'MM/dd/yyyy' )}
											</TimelineOppositeContent>
											<TimelineSeparator>
												<TimelineDot/>
												<TimelineConnector/>
											</TimelineSeparator>
											<TimelineContent>
												<Stack direction='row' alignItems='start' spacing={1}>
													<SingleInvoice invoice={invoice}/>
													<Actions
														items={[ {
															name   : 'View',
															onClick: () => closeModal(),
															props  : {
																component: PageLinkComponent,
																href     : staff
																	? `/dashboard/commerce/${loweredType}s/${invoice.id}`
																	: `/p/${invoice.id}/${loweredType}`,
															},
														}, ( invoicesEditable || ordersEditable ) && {
															name   : 'Edit',
															onClick: () => closeModal(),
															props  : {
																component: PageLinkComponent,
																href     : `/dashboard/commerce/${loweredType}s/${invoice.id}/edit`,
															},
														} ]}
													/>
												</Stack>
											</TimelineContent>
										</TimelineItem>
									</Fade>
								) )}
							</TransitionGroup>
						</Timeline>
					) : (
						<Typography color='text.secondary' textAlign='center' p={2}>
							No {loweredType}s have been generated yet
						</Typography>
					)}
					{isFetching ? <RecurringInvoicesSkeleton/> : null}
				</Box>
			</Fade>
		</PageWrapper>
	);
}
